@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';

.socials {
  display: flex;
  column-gap: vw_d(20);

  @include screen('mobile') {
    column-gap: vw(15);
  }

  img {
    cursor: pointer;
    width: vw_d(35);
    height: vw_d(35);

    @include screen('mobile') {
      width: vw(33);
      height: vw(33);
    }
  }
}

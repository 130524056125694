@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';
.main-wrapper {
  .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .first-section {
      margin-top: vw_d(200);
    }

    .section {
      margin-top: vw_d(73 + 40);

      @include screen('mobile') {
        margin-top: vw(360);
      }
    }
  }
}

.second-image {
  height: vw_d(260);
  width: vw_d(140);
  margin-left: vw_d(120);
  margin-bottom: vw_d(50);

  @include screen('mobile') {
    height: vw(260);
    width: vw(140);
    margin: 0;
  }
}

.fourth-image {
  height: vw_d(200);
  width: vw_d(200);
  margin-left: vw_d(30);
  margin-top: vw_d(70);

  @include screen('mobile') {
    height: vw(200);
    width: vw(200);
    margin: 0;
  }
}

.sixth-image {
  height: vw_d(150);
  width: vw_d(180);
  margin-left: vw_d(30);
  margin-bottom: vw_d(50);

  @include screen('mobile') {
    height: vw(150);
    width: vw(180);
    margin: 0;
  }
}

.eighth-image {
  height: vw_d(200);
  width: vw_d(230);
  margin-left: vw_d(105);

  @include screen('mobile') {
    height: vw(200);
    width: vw(230);
    margin: 0;
  }
}

.tenth-image {
  height: vw_d(200);
  width: vw_d(250);
  margin-top: vw_d(60);

  @include screen('mobile') {
    height: vw(200);
    width: vw(230);
    margin: 0;
  }
}

.twelfth-image {
  height: vw_d(200);
  width: vw_d(180);
  margin-top: vw_d(60);
  margin-right: vw_d(50);

  @include screen('mobile') {
    height: vw(200);
    width: vw(180);
    margin: 0;
  }
}

.fourteenth-image {
  height: vw_d(200);
  width: vw_d(200);
  margin-top: vw_d(40);

  @include screen('mobile') {
    height: vw(200);
    width: vw(180);
    margin: 0;
  }
}

.sixteenth-image {
  height: vw_d(200);
  width: vw_d(150);
  margin-top: vw_d(70);
  margin-left: vw_d(10);

  @include screen('mobile') {
    height: vw(217);
    width: vw(163);
    margin: 0;
  }
}

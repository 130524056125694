@import './fonts.scss';
@import './theme.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: $regular-font;
  background-color: #fffae7;
  overflow-x: hidden;
}

div {
  box-sizing: border-box;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  padding: 0;
  color: inherit;
  background-color: transparent;
}

p {
  margin: 0;
}

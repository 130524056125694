@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';

.footer {
  margin-top: vw_d(177 + 50);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: vw_d(10);

  @include screen('mobile') {
    margin-top: vw(150);
    padding-bottom: vw(30);
  }

  .images-wrapper {
    display: flex;
    column-gap: vw_d(20);

    @include screen('mobile') {
      column-gap: vw(20);
    }

    & img {
      height: vw_d(20);

      @include screen('mobile') {
        height: vw(20);
      }
    }
  }

  .texts-wrapper {
    margin-top: vw_d(10);
    display: flex;
    align-items: center;
    font-size: vw_d(14);
    column-gap: vw_d(10);

    @include screen('mobile') {
      margin-top: vw(10);
      font-size: vw(14);
      column-gap: vw(10);
    }

    & div {
      background-color: #000;
      width: 1px;
      height: vw_d(14);

      @include screen('mobile') {
        height: vw(14);
      }
    }
  }
}

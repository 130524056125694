@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';

.section {
  position: relative;

  .img-wrapper {
    position: relative;
    z-index: 2;
    width: vw_d(300);
    height: vw_d(300);
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen('mobile') {
      display: flex;
      justify-content: flex-end;
      height: auto;
      width: 100%;
    }
  }

  .top-section-content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: vw_d(300);

    @include screen('mobile') {
      display: none;
      min-height: vw(300);
    }
  }

  .bottom-section-content {
    position: relative;

    .bg {
      position: absolute;
      top: vw_d(-115);
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateZ(0);

      @include screen('mobile') {
        left: vw(-21);
        right: vw(-21);
      }

      &::before {
        content: '';
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        transform: translateZ(0);
        visibility: hidden;
        transition: visibility 0s ease 0s;

        @include screen('mobile') {
          display: block;
        }
      }

      svg {
        overflow: visible;

        &.cloned {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          transform: translateZ(0);
        }
      }

      &.odd {
        svg {
          transform-origin: right top;
        }
      }

      &.even {
        svg {
          transform-origin: left top;
        }
      }
    }
  }

  .heading-wrap {
    position: relative;
    height: 1px;
    margin-bottom: vw_d(35);
  }

  .heading {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: vw_d(40);
    transform: translateY(-100%) translateZ(0);

    @include screen('mobile') {
      flex-direction: column;
    }

    &-solid {
      font-family: $bold-font;
      font-size: vw_d(92);
      line-height: vw_d(121);
      white-space: nowrap;
      transition: color 0.15s ease;

      @include screen('mobile') {
        font-size: vw(92);
        line-height: vw(121);
      }
    }

    &-stroked {
      color: transparent;
      margin-top: vw_d(20);
      font-family: $semibold-font;
      font-size: vw_d(64);
      line-height: vw_d(84);
      white-space: nowrap;
      transition: -webkit-text-stroke 0.15s ease, text-stroke 0.15s ease;

      @include screen('mobile') {
        transform-origin: right top;
        margin-top: vw(-40);
        font-size: vw(54);
        line-height: vw(71);
        transform: translateX(vw(-50)) rotate(-10deg);
      }
    }

    span {
      position: relative;
      display: inline-block;
    }
  }

  .title {
    position: relative;
    z-index: 2;
    font-size: vw_d(24);
    max-width: vw_d(772);
    font-family: $semibold-font;
    transition: color 0.2s ease;

    @include screen('mobile') {
      font-size: vw(24);
      max-width: vw(358);
      margin-top: vw(20);
    }
  }

  .children-wrapper {
    position: relative;
    margin-top: vw_d(4);
    max-width: vw_d(820);
    font-size: vw_d(20);
    transition: color 0.3s ease;

    @include screen('mobile') {
      font-size: vw(18);
      margin-top: vw(7);
      max-width: vw(358);
    }
  }

  // .closed {
  //   @include screen('mobile') {
  //     height: vw(205);
  //     overflow: hidden;
  //   }
  // }

  // .opened {
  //   @include screen('mobile') {
  //     height: fit-content;
  //   }
  // }

  .desktop-description {
    @include screen('mobile') {
      display: none;
    }
  }

  .short-description-mobile {
    display: none;
    @include screen('mobile') {
      display: block;
    }
  }

  .long-description-mobile {
    display: none;
    @include screen('mobile') {
      display: block;
    }
  }

  .pop-control {
    position: relative;
    z-index: 2;
    margin-top: vw(7);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include screen('mobile') {
      margin-top: vw(15);
    }

    & span {
      font-size: vw(18);
      font-family: $semibold-font;
    }

    .arrow {
      width: vw(14);
      height: vw(9);
      margin-right: vw(15);
      transition: all 0.3s ease;
    }

    .open {
      transform: rotateZ(180deg);
    }
  }

  &.red {
    color: $red-color;

    .bg {
      &::before {
        background: $red-color;
      }
    }

    .heading-solid {
      color: $red-color;
    }

    .heading-stroked {
      -webkit-text-stroke: 3px $red-color;
      text-stroke: 3px $red-color;

      @include screen('mobile') {
        -webkit-text-stroke: 2px $red-color;
        text-stroke: 2px $red-color;
      }
    }
  }

  &.blue {
    color: $blue-color;

    .bg {
      &::before {
        background: $blue-color;
      }
    }

    .heading-solid {
      color: $blue-color;
    }

    .heading-stroked {
      -webkit-text-stroke: 3px $blue-color;
      text-stroke: 3px $blue-color;

      @include screen('mobile') {
        -webkit-text-stroke: 2px $blue-color;
        text-stroke: 2px $blue-color;
      }
    }
  }

  &.yellow {
    color: $yellow-color;

    .bg {
      &::before {
        background: $yellow-color;
      }
    }

    .heading-solid {
      color: $yellow-color;
    }

    .heading-stroked {
      -webkit-text-stroke: 3px $yellow-color;
      text-stroke: 3px $yellow-color;

      @include screen('mobile') {
        -webkit-text-stroke: 2px $yellow-color;
        text-stroke: 2px $yellow-color;
      }
    }
  }

  &.revealed {
    color: $white-color;

    .bg {
      &::before {
        visibility: visible;
        transition: visibility 0s ease 1s;
      }
    }

    .heading-solid {
      color: $white-color;
    }

    .heading-stroked {
      color: transparent;
      -webkit-text-stroke: 3px $white-color;
      text-stroke: 3px $white-color;
    }

    .pop-control {
      span {
        color: $white-color;
      }

      .arrow {
        path {
          stroke: $white-color;
        }
      }
    }
  }
}

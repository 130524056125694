@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';

.accessablityBtn {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: vw_d(139);

  @include screen('mobile') {
    top: vw(79);
  }

  & img {
    width: vw_d(41);

    @include screen('mobile') {
      width: vw(41);
    }
  }
}

@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/theme.scss';

$base-color: #fffae7;
$base-red-color: #ff6464;

.top-section-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include screen('mobile') {
    overflow: hidden;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: vw_d(580);
    z-index: 0;

    @include screen('mobile') {
      height: vw(520);
    }

    svg {
      overflow: visible;
    }
  }

  .logo {
    position: relative;
    z-index: 1;
    margin-top: vw_d(21);

    @include screen('mobile') {
      margin-top: vw(21);
    }

    & img {
      width: vw_d(114);
      height: vw_d(42);

      @include screen('mobile') {
        width: vw(91);
        height: vw(34);
      }
    }
  }

  .letters-wrapper {
    position: relative;
    margin-top: vw_d(40);
    z-index: 2;

    @include screen('mobile') {
      margin-top: vw(33);
    }

    .letters {
      position: relative;

      p {
        position: absolute;
        font-family: $medium-font;
        font-size: vw_d(110);
        white-space: nowrap;

        @include screen('mobile') {
          font-size: vw(85);
        }
      }

      .top {
        color: transparent;
        right: vw_d(-190);
        color: $white-color;
        transition: color 0.2s ease;

        span {
          position: relative;
          display: inline-block;
        }

        @include screen('mobile') {
          right: vw(-180);
        }
      }

      .bottom {
        color: transparent;
        transform: rotateZ(-8deg);
        top: vw_d(120);
        -webkit-text-stroke: 3px $white-color;
        text-stroke: 3px $white-color;
        transition: -webkit-text-stroke 0.2s ease, text-stroke 0.2s ease;

        span {
          position: relative;
          display: inline-block;
        }

        @include screen('mobile') {
          right: vw(-20);
          top: vw(80);
        }
      }
    }
  }

  .person-img {
    position: relative;
    z-index: 1;
    margin-top: vw_d(274 + 75);

    @include screen('mobile') {
      margin-top: vw(274);
    }

    & img {
      width: vw_d(365);
      height: vw_d(365);

      @include screen('mobile') {
        height: vw(394);
        width: vw(394);
      }
    }
  }

  .description {
    margin-top: vw_d(198);
    text-align: center;
    width: vw_d(678);
    font-size: vw_d(27);
    color: $base-red-color;

    @include screen('mobile') {
      margin-top: vw(145);
      width: vw(358);
      font-size: vw(24);
      line-height: vw(31);
    }
  }

  .red-line {
    margin-top: vw_d(30);
    width: vw_d(400);
    background-color: $base-red-color;
    height: vw_d(1);

    @include screen('mobile') {
      margin-top: vw(26);
      width: vw(358);
    }
  }

  .mentioned-block {
    margin-top: vw_d(30);
    color: $base-red-color;
    font-size: vw_d(23);

    @include screen('mobile') {
      margin-top: vw(26);
      font-size: vw(20);
      line-height: vw(26);
    }

    & span {
      font-family: $semibold-font;
    }
  }

  .socials {
    margin-top: vw_d(35);

    @include screen('mobile') {
      margin-top: vw(32);
    }
  }

  &.revealed {
    .letters-wrapper {
      .letters {
        .top {
          color: $red-color;
        }

        .bottom {
          -webkit-text-stroke: 3px $red-color;
          text-stroke: 3px $red-color;
        }
      }
    }
  }
}

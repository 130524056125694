@import 'theme';

/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('small') {
			width: 90%;
		}
		@include screen('medim') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */

@mixin screen($size) {
  $mediaQueries: (
    'mobile_sm': $mq-mobile_sm,
    'mobile': $mq-mobile_md,
  );

  // Use predefined media query
  @each $key, $value in $mediaQueries {
    @if $key == $size {
      @media only screen and #{$value} {
        @content;
      }
    }
  }

  // Use custom media query
  @if map-has-key($mediaQueries, $size) != true {
    @media only screen and #{$size} {
      @content;
    }
  }
}
